@import "../../../assets/theme/_var.scss";

// @import "../../../assets/theme/_var.scss";
.question_right {
    video {
        margin-inline: auto;
        height: 100vh;
        object-fit: cover;
        object-position: top;
        display: block;
        width: 100%;
    }

}