@import "../../../../assets/theme/_var.scss";


.success_modal {
    :global {
        .modal-dialog {
            max-width: 67.7rem;
            padding: 1.2rem;

            .modal-content {
                border: 1px solid rgba($white, 0.1);
                border-bottom: 2px solid $green;
                border-radius: 0.4rem 0.4rem 0 0;
                box-shadow: 0px 20px 20px 0px #00000014;
                background-color: $black18;

                .modal-header {
                    border-bottom: 0;
                    padding: 3.6rem 4rem 0;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    @media (max-height: 479px) and (max-width: 1199px) {
                        padding: 1rem 1.5rem 0;
                    }

                    @media (max-width: 575px) {
                        padding: 1rem 1rem 0;
                    }

                    .modal-title {
                        font-size: 2rem;
                        font-weight: 400;
                        line-height: 2.3rem;
                        color: $white;
                        margin-bottom: 0.8rem;

                        @media (max-width: 1439px) {
                            font-size: 1.8rem;
                            line-height: 2.2rem;
                        }

                        @media (max-width: 1199px) {
                            font-size: 1.6rem;
                            line-height: 2.1rem;
                        }

                        @media (max-width: 991px) {
                            font-size: 1.4rem;
                            margin-bottom: 0.4rem;
                        }
                    }
                }

                .modal-body {
                    padding: 0 4rem 3.6rem;

                    @media (max-height: 479px) and (max-width: 1199px) {
                        padding: 0 1rem 1.5rem;
                    }

                    @media (max-width: 575px) {
                        padding: 0 1rem 1rem;
                    }
                }
            }
        }
    }

    .close_btn {
        padding: 0;
        border: none;
        position: absolute;
        right: 2rem;
        top: 2rem;
        outline: none;
        background-color: transparent;
    }

    p {
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 2.2rem;
        color: rgba($white, 0.6);
        margin-bottom: 2rem;

        @media (max-width: 991px) {
            font-size: 1.2rem;
            margin-bottom: 0.4rem;
        }

        &:last-child {
            margin-bottom: 0;
        }

        span {
            color: $yellowclr2;
        }
    }
}

.success_msg {
    padding: 3.6rem 3rem 3rem;
    font-family: $basefont;
    margin-inline: auto;
    max-width: 65.3rem;
    box-shadow: 0px 20px 20px 0px #00000014;
    width: max-content;

    @media (max-width: 1439px) {
        padding: 1.6rem 1.5rem 1rem;
    }

    @media (max-width: 767px) {
        max-width: calc(100vw - 2rem);

    }


    h2 {
        font-size: 2rem;
        font-weight: 400;
        line-height: 2.3rem;
        color: $white;
        margin-bottom: 0.8rem;

        @media (max-width: 1439px) {
            font-size: 1.8rem;
            line-height: 2.2rem;
        }

        @media (max-width: 1199px) {
            font-size: 1.6rem;
            line-height: 2.1rem;
        }

        @media (max-width: 991px) {
            font-size: 1.4rem;
            margin-bottom: 0.4rem;
        }
    }

    p {
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 2.2rem;
        color: rgba($white, 0.6);
        margin-bottom: 2rem;

        @media (max-width: 991px) {
            font-size: 1.2rem;
            margin-bottom: 0.4rem;
        }

        &:last-child {
            margin-bottom: 0;
        }

        span {
            color: $yellowclr2;
        }
    }
}