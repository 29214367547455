@import "../../../assets/theme/_var.scss";

.verify_page {

    .archery_icon {
        max-width: fit-content;
        margin-inline: auto;
        display: block;
        margin-bottom: 1.7rem;
        max-width: 20rem;

        @media (max-height: 379px) {
            max-width: 7rem;
            margin-bottom: 1rem;
        }

        @media (max-height: 799px) and (max-width: 1439px) {
            max-width: 8rem;
        }
    }

    .logo {
        z-index: 1;
        position: relative;
    }

    .animation {
        margin-top: -1rem;
        z-index: 0;
        margin-inline: auto;
        max-width: 26.4rem;
    }

    @media (max-width: 1439px) {
        .animation {
            max-width: 25rem;
        }
    }

    @media (max-width:1199px) {
        .animation {
            max-width: 20rem;
        }
    }

    @media (max-height: 479px) and (max-width: 1439px) {
        .animation {
            max-width: 15rem;
        }
    }

    @media (max-height: 419px) and (max-width: 1439px) {
        .animation {
            max-width: 12rem;
        }
    }

    form {

        .input {
            // justify-content: flex-end;
            margin-bottom: 1.2rem;
            label {
                white-space: normal;
            }
            @media (max-width: 991px) {
                justify-content: center;
            }

            @media (min-width: 576px) {
                label {
                    text-align: right;
                    width: 10rem;
                }
            }

            &:last-of-type {
                margin-bottom: 6.2rem;

                @media (max-height: 619px) and (max-width: 1439px) {
                    margin-bottom: 4rem;
                }

                @media (max-height: 479px) and (max-width: 1199px) {
                    margin-bottom: 2rem;
                }

                @media (max-height: 419px) and (max-width: 1199px) {
                    margin-bottom: 1rem;
                }
            }

            >div {
                input {
                    @media (min-width: 576px) {
                        width: 20.5rem !important;
                    }

                    @media (min-width: 992px) {
                        width: 31.5rem !important;
                    }

                }
            }
        }
    }
}