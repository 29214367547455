@import "../../../assets/theme/_var.scss";

.header {
    background-color: rgba($black, 0.1);

    .header_inner {
        padding: 1.6rem 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-height: 767px) and (max-width: 1439px) {
            padding: 1rem 0;
        }

        @media (max-height: 479px) and (max-width: 1439px) {
            padding: 0.6rem 0;
        }

        .logo {
            max-width: 11.135rem;

            img {
                max-height: 6.9rem;
            }

            @media (max-height: 479px) and (max-width: 1439px) {
                max-width: 6rem;
            }
        }
    }
}