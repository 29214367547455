@import "../../../../assets/theme/_var.scss";

.welcome {
    :global {
        .modal-dialog {
            max-width: 80rem;
            padding: 1.5rem;
            margin: auto;
            min-height: 100dvh;

            @media (max-width: 575px) {
                max-width: 60rem;
            }

            @media (max-width: 479px) {
                max-width: 50rem;
            }

            @media (max-height: 619px) and (max-width: 1199px) {
                max-width: 50rem;
            }

            .modal-content {
                padding: 9.4rem 2rem 8.7rem;
                background: linear-gradient(180deg, #221509, #221509) padding-box, linear-gradient(180deg, #FFBA55 1.32%, rgba(255, 186, 85, 0.19) 48.44%, #FFBA55 93.51%) border-box;
                border: 2px solid transparent;
                z-index: 1;

                @media (max-width: 1439px) and (max-height: 699px) {
                    padding: 3rem 2rem;
                }

                &::after {
                    content: "";
                    width: 28.9rem;
                    height: 0.2rem;
                    background-color: #221509;
                    position: absolute;
                    z-index: -1;
                    top: 0;
                    left: 50%;
                    translate: -50% -0.2rem;

                    @media (max-width: 575px) {
                        max-width: 20rem;
                    }

                    @media (max-width: 479px) {
                        max-width: 16rem;
                    }

                    @media (max-height: 619px) and (max-width: 1199px) {
                        max-width: 14rem;
                    }
                }
            }
        }
    }

    .bow {
        position: absolute;
        top: 0;
        left: 50%;
        translate: -50% -4.7rem;
        max-width: 28.9rem;

        @media (max-width: 575px) {
            max-width: 20rem;
            translate: -50% -3.1rem;
        }

        @media (max-width: 479px) {
            max-width: 16rem;
            translate: -50% -2.4rem;
        }

        @media (max-height: 619px) and (max-width: 1199px) {
            max-width: 14rem;
            translate: -50% -2.3rem;
        }
    }

    .flag {
        display: block;
        max-width: 24.2rem;
        margin: 0 auto 4.5rem;

        @media (max-width: 575px) {
            max-width: 20rem;
        }

        @media (max-width: 479px) {
            max-width: 16rem;
        }

        @media (max-height: 619px) and (max-width: 1199px) {
            margin: 0 auto 2rem;
            max-width: 14rem;
        }

        @media (max-height: 555px) and (max-width: 1199px) {
            max-width: 10rem;
        }

    }

    .title {
        display: flex;
        align-items: center;
        justify-content: center;

        >img {
            width: 11.9rem;
            flex-shrink: 0;


            @media (max-width: 1199px) {
                width: 10rem;
            }

            @media (max-width: 991px) {
                width: 8rem;
            }

            @media (max-width: 767px) {
                width: 6rem;
            }

            @media (max-width: 575px) {
                width: 5.4rem;
            }

            @media (max-width: 479px) {
                width: 5rem;
            }

            @media (max-height: 619px) and (max-width: 1199px) {
                width: 5rem;
            }
        }

        h2 {
            font-size: 4rem;
            font-weight: 400;
            line-height: 5.6rem;
            text-align: center;
            max-width: 40rem;
            color: $yellowclr2;
            font-family: $hindifont;

            @media (max-width: 1199px) {
                font-size: 3.6rem;
                line-height: 1.6;
            }

            @media (max-width: 991px) {
                font-size: 3.2rem;
            }

            @media (max-width: 767px) {
                font-size: 2.8rem;
            }

            @media (max-width: 575px) {
                font-size: 2.4rem;
            }

            @media (max-width: 479px) {
                font-size: 1.6rem;
            }

            @media (max-height: 619px) and (max-width: 1199px) {
                font-size: 2.6rem;
            }

            @media (max-height: 479px) and (max-width: 1199px) {
                font-size: 2.4rem;
            }

            @media (max-height: 419px) and (max-width: 1199px) {
                font-size: 2rem;
            }

            @media (max-height: 555px) and (max-width: 1199px) {
                font-size: 1.6rem;
            }
        }
    }

    .enter_btn {
        position: absolute;
        bottom: 0;
        left: 50%;
        translate: -50% 50%;
        width: 22.6rem;
        height: 5.7rem;
        font-size: 2rem;
        font-weight: 400;
        line-height: 2rem;
        letter-spacing: 0.01em;
        text-align: center;

        @media (max-height: 555px) and (max-width: 1199px) {
            width: 18rem;
            height: 4.6rem;
            font-size: 1.2rem;
        }

        // &:hover{
        //     background-image: url($s3bucket + "/btn-bg.png");
        // }
    }
}