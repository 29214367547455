@import "../../../../assets/theme/_var.scss";

body {
    :global {
        .certificate_backdrop {
            &.show {
                opacity: 1;
            }
        }
    }
}

.certificate {
    :global {
        .modal-dialog {
            .modal-content {
                padding: 3rem 2rem 5rem;

                .modal-body {
                    position: static;

                    h3 {
                        text-align: center;
                        margin-bottom: 2rem;
                    }
                }
            }
        }
    }

    .certificate_img {
        max-width: 60rem;
        margin-inline: auto;
        display: block;
        width: 100%;
        // max-height: calc(100vh - 23rem);
    }

    h4 {
        font-size: 2.4rem;
        color: $baseclr;
        text-align: center;

        @media (max-width: 1679px) {
            font-size: 2rem;
        }

        @media (max-width: 1599px) {
            font-size: 1.8rem;
        }

        @media (max-width: 1365px) {
            font-size: 1.6rem;
        }

        @media (max-width: 1199px) {
            font-size: 1.4rem;
            line-height: 2rem;
        }

        @media (max-width: 991px) {
            font-size: 1.2rem;
        }

        @media (max-width: 767px) {
            font-size: 1.1rem;
            line-height: 1.4rem;
        }
    }

    .continue {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%) !important;
    }
}