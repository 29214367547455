@import "../../../assets/theme/_var.scss";

.table {
    border-spacing: 0 0.4rem;
    border-collapse: separate;
    margin-top: -0.4rem;

    thead {

        @media (max-width: 479px) {
            display: none;
        }

        tr {
            th {
                background-color: rgba($yellowclr, 0.1);
                font-size: 1.6rem;
                font-weight: 400;
                line-height: 1.8rem;
                letter-spacing: 0.01em;
                color: $textclr;
                padding: 1.4rem 2rem;
                vertical-align: middle;
                white-space: nowrap;
                border: none;

                @media (max-height: 619px) and (max-width: 1439px) {
                    font-size: 1.4rem;
                    padding: 1rem 1.6rem;
                }

                @media (max-height: 479px) and (max-width: 1439px) {
                    font-size: 1.2rem;
                }

                &:first-child {
                    border-radius: 0.4rem 0 0 0.4rem;
                    padding-left: 4rem;
                }

                &:last-child {
                    border-radius: 0.4rem 0.4rem 0 0;
                }
            }
        }
    }

    tbody {
        tr {
            td {
                border: none;
                background-color: rgba($white, 0.1);
                color: rgba($white, 0.6);
                font-size: 1.6rem;
                font-weight: 400;
                padding: 0.9rem 2rem;
                vertical-align: middle;
                white-space: nowrap;
                line-height: 2.2rem;
                text-align: left;

                @media (max-height: 619px) and (max-width: 1439px) {
                    font-size: 1.4rem;
                    padding: 0.9rem 1.6rem;
                }

                @media (max-height: 479px) and (max-width: 1439px) {
                    font-size: 1.2rem;
                    padding: 0.6rem 1rem;
                }

                &:first-child {
                    border-radius: 0.4rem 0 0 0.4rem;
                    padding-left: 4rem;
                }

                &:last-child {
                    border-radius: 0.4rem 0.4rem 0 0;
                }

                .no_record_box {
                    padding: 2rem 0;
                }

                @media (max-width: 479px) {
                    font-size: 1.4rem;

                    .no_record_box {
                        text-align: center;
                        width: 100%;
                        padding: 1rem 0;
                    }

                    &:first-child {
                        border-radius: 0.4rem 0.4rem 0 0;
                        padding: 1.4rem 2rem;
                    }

                    &:last-child {
                        border-radius: 0 0 0.4rem 0.4rem;
                    }
                }

                @media (max-height: 575px) {
                    padding: 1rem;
                    font-size: 1.2rem;

                    &:first-child {
                        padding: 1rem;
                    }
                }

                :global {
                    .table_heading {
                        display: none;
                    }

                    @media (max-width: 479px) {
                        border: none;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .table_heading {
                            display: inline-block;
                            color: $textclr;
                            padding-right: 1rem;
                        }

                        .table_data {
                            text-align: right;
                        }
                    }
                }
            }
        }
    }
}