@import "../../../assets/theme/_var.scss";

.my_collectibles {
    padding: 5rem 0 2rem;

    .collectibles_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 2.4rem;

        @media (max-width: 767px) {
            display: block;
            margin-bottom: 1.8rem;

            .heading {
                margin-bottom: 2rem;
            }
        }

        @media (max-height: 679px) and (max-width: 1439px) {
            margin-bottom: 1.4rem;
        }



        ul {
            display: flex;
            align-items: center;
            padding: 1.2rem 0;
            border-radius: 0.4rem;
            background-color: rgba($white, 0.1);

            @media (max-width: 767px) {
                justify-content: center;
            }

            @media (max-height: 619px) and (max-width: 1439px) {
                padding: 1rem 1.2rem;
            }

            @media (max-width: 575px) {
                display: block;
                padding: 1rem 1.2rem;
            }

            li {
                border-right: 1px solid $baseclr;
                padding-inline: 2rem;
                display: flex;
                align-items: center;

                @media (max-height: 619px) and (max-width: 1439px) {
                    padding-inline: 1rem;
                }

                @media (max-width: 575px) {
                    border-right: 0;
                    border-bottom: 1px solid $baseclr;
                    padding: 1rem 0;
                    justify-content: space-between;

                    &:first-child {
                        padding-top: 0;
                    }

                    &:last-child {
                        padding-bottom: 0;
                        border-bottom: 0;
                    }
                }

                h4 {
                    font-size: 1.8rem;
                    color: $baseclr;
                    font-weight: 400;

                    @media (max-width: 1439px) {
                        font-size: 1.6rem;
                    }

                    @media (max-width: 767px) or (max-height: 619px) {
                        font-size: 1.4rem;
                    }

                    @media (max-height: 479px) and (max-width: 1439px) {
                        font-size: 1.2rem;
                    }
                }

                strong {
                    margin-left: 1rem;
                    font-size: 2.4rem;
                    font-weight: 400;
                    color: $white;

                    @media (max-width: 1439px) {
                        font-size: 2.2rem;
                    }

                    @media (max-width: 1199px) {
                        font-size: 2rem;
                    }

                    @media (max-width: 991px) {
                        font-size: 1.8rem;
                    }

                    @media (max-width: 767px) or (max-height: 619px) {
                        font-size: 1.6rem;
                    }

                    @media (max-height: 479px) and (max-width: 1439px) {
                        font-size: 1.4rem;
                    }

                    @media (max-width: 575px) {
                        text-align: right;
                    }
                }

                &:last-child {
                    border-right: 0;
                }
            }
        }
    }

    .collectibles_table {
        thead {
            tr {
                th {
                    &:first-child {
                        @media (min-width: 480px) and (max-height: 619px) and (max-width: 1199px) {
                            max-width: 10rem;
                            padding: 0.3rem 1rem;
                        }
                    }
                }
            }
        }

        tbody {
            tr {
                td {
                    text-align: center;

                    &:first-child {
                        @media (min-width: 480px) and (max-height: 619px) and (max-width: 1199px) {
                            max-width: 10rem;
                            padding: 0.3rem 1rem;
                        }
                    }

                    &.serial {
                        color: $white;
                    }

                    .table_img {
                        width: 4.2rem;
                        height: 4.2rem;
                        object-fit: cover;
                        object-position: center;
                        border: 1px solid $baseclr;
                        border-radius: 0.4rem;

                        @media (max-height: 479px) or (max-width: 991px) {
                            width: 3.2rem;
                            height: 3.2rem;
                        }
                    }

                    &.asset_name {
                        color: $baseclr;
                    }
                }
            }
        }
    }
}