@import "../../../../assets/theme/_var.scss";

.details {
    h4 {
        font-size: 2rem;
        font-weight: 500;
        color: $baseclr2;
        text-align: center;

        @media (max-width: 1679px) {
            font-size: 1.8rem;
        }

        @media (max-width: 1479px) {
            font-size: 1.6rem;
        }

        @media (max-width: 1439px) {
            font-size: 1.4rem;
        }
    }

    ul {
        margin: 3rem 0;

        @media (max-width: 1439px) {
            margin: 2rem 0;
        }

        li {
            display: flex;
            align-items: flex-start;

            &:not(:last-child) {
                margin-bottom: 1rem;
            }

            h5 {
                color: $textclr;
                width: 100%;
                max-width: 40%;
                line-height: 2.2rem;
                font-size: 1.4rem;
            }

            p {
                color: $yellowclr2;
                max-width: 60%;
                width: 100%;
                font-size: 1.4rem;
                line-height: 2.2rem;
            }

            @media (max-width: 1439px) {

                h5,
                p {
                    line-height: 1.8rem;
                    font-size: 1.2rem;
                }
            }
        }
    }
}