@import "../../../assets/theme/_var.scss";

.brick {
    padding: 2.8rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100dvh;

    @media (max-height: 379px) {
        padding: 1.6rem 0;
    }

    .logo {
        max-width: 11.135rem;
        display: block;

        img {
            max-height: 6.9rem;
        }

        @media (max-height: 479px) and (max-width: 1439px) {
            max-width: 6rem;
        }

        margin: 0 auto 5rem;

        @media (max-width: 1439px) {
            margin: 0 auto 4rem;
        }

        @media (max-width: 1199px) {
            margin: 0 auto 3rem;
        }

        @media (max-width: 991px) {
            margin: 0 auto 2rem;
        }

        @media (max-height: 619px) and (max-width: 1439px) {
            margin: 0 auto 3rem;
        }

        @media (max-height: 479px) and (max-width: 1199px) {
            margin: 0 auto 2rem;
        }

        @media (max-height: 419px) and (max-width: 1199px) {
            margin: 0 auto 1rem;
        }
    }

    h2 {
        font-size: 3.2rem;
        font-weight: 400;
        line-height: 3.6rem;
        text-align: center;
        color: $baseclr;
        max-width: 50.6rem;
        margin: 0 auto 5rem;

        @media (max-width: 1439px) {
            font-size: 2.8rem;
            margin: 0 auto 4rem;
        }

        @media (max-width: 1199px) {
            font-size: 2.4rem;
            margin: 0 auto 3rem;
        }

        @media (max-width: 991px) {
            font-size: 2.2rem;
            margin: 0 auto 2rem;
        }

        @media (max-width: 767px) {
            font-size: 2rem;
        }

        @media (max-height: 619px) and (max-width: 1439px) {
            font-size: 2rem;
            margin: 0 auto 3rem;
        }

        @media (max-height: 479px) and (max-width: 1199px) {
            margin: 0 auto 2rem;
            font-size: 1.8rem;
        }

        @media (max-height: 419px) and (max-width: 1199px) {
            font-size: 1.6rem;
            margin: 0 auto 1rem;
            line-height: 2.4rem;
        }
    }

    :global {
        .container {
            max-width: 89.6rem;


            .row {
                margin: -2.3rem;

                >div {
                    padding: 2.3rem;
                }
            }

            @media (max-width: 1439px) {
                max-width: 70rem;
                padding-inline: 2.3rem;
            }

            @media (max-height: 619px) and (max-width: 1439px) {
                max-width: 60rem;
            }

            @media (max-height: 479px) and (max-width: 1199px) {
                max-width: 40rem;
            }

            @media (max-height: 379px) {
                .row {
                    margin: -0.8rem;

                    >div {
                        padding: 0.8rem;
                        width: 100%;
                    }
                }
            }

        }
    }

    .asset_box {
        width: 100%;
        cursor: pointer;

        .asset_box_inner {
            position: relative;
            border: 2px solid transparent;
            background: linear-gradient(180deg, #000, #000) padding-box, linear-gradient(180deg, #6F6F6F 18.46%, #FFFFFF 77.53%, #3F3F3F 134.04%) border-box;

            @media (max-height: 379px) {
                max-width: 14rem;
                margin: auto;
            }
        }

        .asset_img {
            height: 41rem;
            position: relative;
            width: 100%;
            display: flex;
            padding: 4rem;
            align-items: center;
            justify-content: center;

            @media (max-width: 1439px) {
                height: 31rem;
            }

            @media (max-height: 619px) and (max-width: 1439px) {
                height: 24rem;
            }

            @media (max-height: 479px) and (max-width: 1199px) {
                height: 14rem;
                padding: 2rem;
            }

            @media (max-height: 379px) {
                padding: 1rem;
            }

            P {
                font-size: 1.6rem;
                text-align: center;
                margin-top: 1rem;
                color: $baseclr;
                position: absolute;
                top: 1rem;
                left: 1rem;

                @media (max-height: 619px) and (max-width: 1439px) {
                    font-size: 1.4rem;
                }

            }

            img {
                flex-shrink: 1;
                width: 100%;
                max-height: 100%;
                object-fit: cover;
                object-position: top;
            }
        }

        .left_arrow,
        .right_arrow {
            position: absolute;
            top: 50%;
            display: none;
        }

        .left_arrow {
            left: 0;
            transform: translateX(-100%) translateY(-50%);
        }

        .right_arrow {
            right: 0;
            transform: translateX(100%) translateY(-50%);
        }

        .buy_btn {
            margin: 4.8rem auto 0;
            display: block;

            @media (max-width: 479px) {
                margin: 2rem auto 0;
            }

            @media (max-height: 619px) and (max-width: 1439px) {
                margin: 3rem auto 0;
                font-size: 2rem;
            }

            @media (max-height: 479px) and (max-width: 1199px) {
                margin: 2rem auto 0;
                font-size: 1.6rem;
            }

            @media (max-height: 379px) {
                font-size: 1.4rem;
                margin: 1rem auto 0;
                line-height: 1.2;
            }
        }

        P {
            font-size: 1.6rem;
            text-align: center;
            margin-top: 1rem;
            color: $textclr2;

            @media (max-height: 619px) and (max-width: 1439px) {
                font-size: 1.4rem;
            }

            @media (max-height: 489px) and (max-width: 1439px) {
                font-size: 1.2rem;
            }
        }

        // &.active {
        &:hover {
            .asset_box_inner {
                box-shadow: 0px 0px 80px 0px #FFBA554D;
                background: linear-gradient(180deg, #000, #000) padding-box, linear-gradient(179.54deg, #FFBA55 0.39%, rgba(255, 186, 85, 0.19) 50.37%, #FFBA55 100.26%) border-box;

                // .asset_img {
                // &::after {
                //     content: unset;
                // }
                // }

                .left_arrow,
                .right_arrow {
                    display: inline-block;
                }
            }

            h3,
            p {
                color: $baseclr;
            }
        }
    }
}