@import "../../../assets/theme/_var.scss";

.access_collectibles {
    padding: 4.6rem 0 10rem;
    min-height: 100dvh;
    display: flex;
    align-items: center;

    @media (max-width: 1439px) {
        padding: 2rem 0 6rem;
    }

    @media (max-width: 1199px) {
        padding: 2rem 0 4rem;
    }

    // @media (max-width: 479px) {
    //     min-height: 100dvh;
    //     display: flex;
    //     align-items: center;
    // }

    @media (max-height: 767px) and (max-width: 1199px) {
        padding: 2rem 0 6rem;
    }

    @media (max-height: 619px) {
        padding: 2rem 0 3rem;
    }

    .access_box {
        margin: 0 auto;
        max-width: 57.1rem;
        background-color: rgba($black23, 0.8);
        min-height: 25rem;
        border: 2px solid $baseclr2;
        padding: 3.9rem 1.1rem 9rem;

        @media (max-width: 1439px) {
            padding: 3rem 1rem 4rem;
        }

        @media (max-width: 991px) {
            padding: 2rem 1rem;
        }

        @media (max-height: 419px) {
            padding: 1.5rem 1rem 2rem;
        }


        .logo {
            margin-inline: auto;
            display: block;
            max-width: 13.4rem;

            @media (max-width: 1439px) {
                max-width: 10rem;
            }

            @media (max-width: 991px) {
                max-width: 6rem;
            }

            @media (max-height: 619px) and (max-width: 1199px) {
                max-width: 6rem;
            }
        }

        .heading {
            margin: 4.6rem 0 8.5rem;
            text-align: center;

            @media (max-width: 1439px) {
                margin: 4rem 0 5rem;
            }

            @media (max-height: 619px) and (max-width: 1199px) {
                margin: 2rem 0;
            }

            @media (max-height: 419px) {
                margin: 1rem 0;
            }
        }

        .mail_form {
            max-width: 48.5rem;
            margin-inline: auto;
            padding: 0 0 3.6rem;

            @media (max-width: 991px) {
                padding: 0 0 2rem;
            }

            @media (max-width: 575px) {
                padding: 0 0 1rem;
            }

            @media (max-height: 619px) {
                padding: 0 0 2rem;
            }

            @media (max-height: 419px) {
                padding: 0 0 1rem;
            }



            .input {
                justify-content: center;

                >div {
                    width: 100%;
                }
            }

            .send_btn {
                margin-top: 0.6rem;
                padding: 0;
                border: none;
                outline: none;
                color: $baseclr;
                font-size: 1.6rem;
                margin-left: auto;
                background-color: transparent;
                display: block;
                width: max-content;
                font-weight: 400;
                line-height: 2.2rem;
                letter-spacing: 0em;
                text-align: right;

                @media (max-width: 1199px) {
                    font-size: 1.4rem;
                }

                @media (max-height: 619px) and (max-width: 1199px) {
                    font-size: 1.4rem;
                }

                @media (max-height: 419px) and (max-width: 991px) {
                    font-size: 1.2rem;
                }

                &:hover {
                    text-decoration: underline;
                }

                &:disabled {
                    opacity: 0.6;
                    pointer-events: none;
                }
            }
        }

        .separator {
            background-color: rgba($yellowclr2, 0.21);
            border: none;
            height: 1px;
            opacity: 1;
            margin: 0;
        }

        .otp_input {
            text-align: center;
            padding-top: 4.6rem;

            @media (max-width: 991px) {
                padding-top: 3rem;
            }

            @media (max-height: 619px) {
                padding-top: 3.2rem;
            }

            @media (max-height: 619px) {
                padding-top: 2rem;
            }

            h3 {
                font-size: 1.6rem;
                font-weight: 400;
                line-height: 2.2rem;
                letter-spacing: 0em;
                color: $baseclr;
                margin-bottom: 2rem;

                @media (max-height: 419px) and (max-width: 991px) {
                    font-size: 1.4rem;
                    margin-bottom: 1rem;
                }
            }

            >div {
                justify-content: center;
            }

            input {
                flex: 0 0 6rem;
                background-color: rgba($black , 0.6);
                height: 6rem;
                border: 1px solid rgba($black , 0.6);
                outline: none;
                border-radius: 0.4rem;
                font-family: $secondaryfont;
                font-size: 2rem;
                font-weight: 400;
                line-height: 2.8rem;
                margin-right: 1rem;
                color: $baseclr;

                @media (max-height: 619px) and (max-width: 1199px) {
                    flex: 0 0 4.4rem;
                    font-size: 1.6rem;
                    height: 4.4rem;
                }

                @media (max-width: 489px) {
                    flex: 0 0 4.4rem;
                    height: 4.4rem;
                    font-size: 1.4rem;
                }

                &:last-child {
                    margin-right: 0;
                }

                &:focus {
                    border-color: rgba($yellowclr2, 0.6);
                }
            }
        }
    }
}