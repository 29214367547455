@import "../../../../assets/theme/_var.scss";

.modal {
    :global {
        .modal-dialog {
            max-width: 80rem;
            padding: 1.5rem;
            margin: auto;
            min-height: 100dvh;

            @media (max-width: 575px) {
                max-width: 60rem;
            }

            @media (max-width: 479px) {
                max-width: 50rem;
            }

            @media (max-height: 619px) and (max-width: 1199px) {
                max-width: 50rem;
            }

            .modal-content {
                padding: 9.4rem 2rem 8.7rem;
                background: linear-gradient(180deg, #221509, #221509) padding-box, linear-gradient(180deg, #FFBA55 1.32%, rgba(255, 186, 85, 0.19) 48.44%, #FFBA55 93.51%) border-box;
                border: 2px solid transparent;
                z-index: 1;

                @media (max-width: 1439px) and (max-height: 699px) {
                    padding: 3rem 2rem;
                }

                &::after {
                    content: "";
                    width: 28.9rem;
                    height: 0.2rem;
                    background-color: #221509;
                    position: absolute;
                    z-index: -1;
                    top: 0;
                    left: 50%;
                    translate: -50% -0.2rem;

                    @media (max-width: 575px) {
                        max-width: 20rem;
                    }

                    @media (max-width: 479px) {
                        max-width: 16rem;
                    }

                    @media (max-height: 619px) and (max-width: 1199px) {
                        max-width: 14rem;
                    }
                }
            }
        }
    }

    .bow {
        position: absolute;
        top: 0;
        left: 50%;
        translate: -50% -4.7rem;
        max-width: 28.9rem;

        @media (max-width: 575px) {
            max-width: 20rem;
            translate: -50% -3.1rem;
        }

        @media (max-width: 479px) {
            max-width: 16rem;
            translate: -50% -2.4rem;
        }

        @media (max-height: 619px) and (max-width: 1199px) {
            max-width: 14rem;
            translate: -50% -2.3rem;
        }
    }

    .title {
        color: $baseclr2;
        font-size: 3.2rem;
        // word-break: break-all;
        word-wrap: break-word;

        @media (max-width: 1679px) {
            font-size: 2.8rem;
        }

        @media (max-width: 1439px) {
            font-size: 2.4rem;
        }

        @media (max-width: 1199px) {
            font-size: 2rem;
        }
    }
}