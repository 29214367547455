@import "../../../assets/theme/_var.scss";


.content_layout {
    :global {
        .container {
            margin-inline: auto 0;
            padding-right: 28.7rem;
            max-width: 152.7rem;

            @media (max-width: 1679px) {
                max-width: 128rem;
                margin-inline: auto;
                padding-right: 1.5rem;
                padding-left: 10rem;
            }

            @media (max-width: 1365px) {
                max-width: 114rem;
                padding-inline: 2rem;
            }

            @media (max-width: 1199px) {
                max-width: 81.1rem
            }
        }
    }

    .content_inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 100dvh;
        padding-top: 6rem;

        @media (max-width: 575px) {
            flex-wrap: wrap;
        }

        @media (max-height: 619px) and (max-width: 1439px) {
            padding-top: 3rem;
        }


        @media (max-height: 479px) and (max-width: 1199px) {
            padding-top: 2rem;
        }

        @media (max-height: 419px) and (max-width: 991px) {
            padding-top: 1rem;
        }

        // @media (max-width: 991px) {
        //     flex-wrap: wrap;
        // }

        .content_left {
            max-width: 100%;
            width: 43.6rem;
            text-align: center;
            padding-bottom: 2rem;

            @media (max-width: 575px) {
                width: 100%;
            }

            @media (max-width: 991px) and (max-height: 619px) {
                width: 40rem;
            }

            &_inner {
                margin: 0 auto;
                max-width: 43.4rem;
            }

        }

        .content_right {
            padding-left: 2rem;
            align-self: flex-end;
            display: flex;

            img {
                max-height: 80rem;
            }

            video {
                max-height: 80rem;
                margin-top: auto;

                @media (max-height: 859px) and (max-width: 1679px) {
                    max-height: 68rem;
                }

                @media (max-height: 575px) and (max-width: 1199px) {
                    max-height: 60rem;
                }

                @media (max-height: 575px) and (max-width: 991px) {
                    max-height: 54rem;
                }

                @media (max-height: 519px) and (max-width: 991px) {
                    max-height: 44rem;
                }

                @media (max-height: 479px) and (max-width: 991px) {
                    max-height: 35rem;
                }

                @media (max-width: 991px) and (max-height: 399px) {
                    max-height: 30rem;
                }
            }

            @media (max-width: 1439px) and (max-height: 991px) {

                img {
                    max-height: 54rem;
                    max-width: 40rem;
                }
            }

            @media (width: 1368px) and (height: 912px) {

                img {
                    max-height: 80rem;
                    max-width: 60rem;
                }
            }

            @media (max-width: 1023px) and (max-height: 991px) {

                img {
                    max-width: 30rem;
                }
            }

            @media (max-width: 575px) {
                text-align: center;
                flex: 0 0 100%;

                img {
                    width: 100%;
                }
            }

            @media (max-height: 619px) and (max-width: 991px) {
                padding-left: 0;
                margin-inline: auto;

                img {
                    max-width: 30rem;
                }
            }

            @media (max-height: 419px) and (max-width: 991px) {

                img {
                    max-width: 23rem;
                }
            }

            // @media (max-height: 479px) and (max-width: 991px) {
            //    {
            //         max-width: 20rem;
            //     }
            // }

            // @media (max-width: 991px) {
            //     flex: 0 0 100%;
            //     text-align: center;
            //     padding-left: 0;
            // }
        }
    }


    &.safari {

        :global {
            .container {
                @media (max-width: 1199px) {
                    max-width: 90rem;
                }
            }
        }

        .content_inner {

            .content_left {
                @media (max-width: 991px) and (max-height: 619px) {
                    width: 30rem;
                }

                @media (max-width: 575px) and (max-height: 329px) {
                    width: 25rem;
                }
            }

            .content_right {
                video {
                    z-index: -1;
                    position: fixed;
                    top: 0;
                    left: 0;
                    height: 100vh;
                    width: 100%;
                    max-height: none;
                    object-fit: fill;
                }
            }
        }
    }
}