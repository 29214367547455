@import "../../../assets/theme/_var.scss";

.view_asset {
  padding-top: 6.8em;
  padding-bottom: 2rem;
  display: flex;
  align-items: center;
  min-height: 100dvh;

  @media (max-width: 1439px) or (max-height: 767px) {
    padding-top: 2rem;
    // min-height: calc(100vh - 10.1rem);
    display: flex;
    align-items: center;
  }

  // @media (max-height: 479px) and (max-width: 1439px) {
  // min-height: calc(100vh - 5.2rem);
  // }

  @media (max-height: 575px) and (max-width: 1439px) {
    padding-bottom: 1rem;
  }

  .back_wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 5rem;
    max-width: 177.2rem;
    padding-inline: 4rem;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 101;

    @media (max-width: 575px) {
      padding-inline: 3rem;
    }

    @media (max-height: 991px) and (max-width: 1439px) {
      top: 5rem;
    }

    @media (max-width: 991px) and (max-height: 479px) {
      top: 3rem;
    }

    @media (max-height: 419px) and (max-width: 991px) {
      top: 2rem;
      padding-inline: 3rem;
    }


    .back_btn {
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 1.8rem;
      border: none;
      outline: none;
      padding: 0;
      background-color: transparent;
      letter-spacing: 0.01em;
      text-align: center;
      color: $baseclr;

      img {
        margin-right: 0.3rem;
        transition: 200ms ease-in;
      }

      @media (max-height: 619px) and (max-width: 1199px) {
        font-size: 1.4rem;
      }

      @media (max-height: 419px) and (max-width: 991px) {
        font-size: 1.2rem;
      }

      &:hover {
        img {
          transform: translateX(-0.4rem);
        }
      }
    }

  }

  :global(.container) {
    max-width: 120.6rem;
    // padding-inline: 0;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: "textfield";
    appearance: textfield;
  }

  &_inner {
    align-items: center;
    display: flex;

    >div {
      flex: 0 0 50%;
    }

    @media (max-width: 479px) {
      flex-wrap: wrap;

      >div {
        flex: 0 0 100%;
      }
    }

    .asset_left {
      .asset_box {
        padding-inline: 2.1rem;

        .asset_box_inner {
          position: relative;
          box-shadow: 0px 0px 99.54249572753906px 0px #ffba554d;
          border: 2.49px solid transparent;
          background: linear-gradient(180deg, #000, #000) padding-box,
            linear-gradient(179.54deg,
              #ffba55 0.39%,
              rgba(255, 186, 85, 0.19) 50.37%,
              #ffba55 100.26%) border-box;
          padding: 4rem;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 54.2rem;

          .asset_img {
            max-height: 100%;
            object-fit: cover;
            object-position: center;
          }

          @media (max-width: 1439px) {
            height: 50rem;
          }

          @media (max-width: 1199px) {
            height: 40rem;
          }

          @media (max-width: 1023px) {
            height: 36rem;
          }

          @media (max-width: 479px) {
            height: 28rem;
          }

          @media (max-height: 619px) and (max-width: 1439px) {
            height: 30rem;
            max-width: 30rem;
            margin-inline: auto;
          }

          @media (max-height: 479px) and (max-width: 1439px) {
            height: 24rem;
          }

          @media (max-height: 419px) and (max-width: 1439px) {
            height: 20rem;
          }
        }

        .left_arrow,
        .right_arrow {
          position: absolute;
          top: 50%;
        }

        .left_arrow {
          left: 0;
          transform: translateX(-100%) translateY(-50%);
        }

        .right_arrow {
          right: 0;
          transform: translateX(100%) translateY(-50%);
        }
      }
    }

    .asset_right {
      padding-left: 2rem;

      p {
        margin: 3rem 0 2rem;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 2.2rem;
        color: rgba($white, 0.6);
        padding-bottom: 2rem;
        border-bottom: rgba($white, 0.3) 1px solid;

        @media (max-height: 619px) and (max-width: 1439px) {
          margin: 1rem 0;
          font-size: 1.4rem;
          padding-bottom: 1rem;
        }

        @media (max-height: 575px) and (max-width: 1439px) {
          font-size: 1.2rem;
        }

        @media (max-width: 1199px) and (max-height: 479px) {
          padding-bottom: 0.4rem;
          margin: 0.4rem 0 1rem;
        }

        @media (max-height: 419px) and (max-width: 1199px) {
          font-size: 1rem;
        }
      }

      ul {
        display: flex;
        align-items: center;
        margin-bottom: 4.5rem;

        @media (max-width: 1439px) and (max-height: 575px) {
          margin-bottom: 2rem;
        }

        @media (max-width: 1199px) and (max-height: 575px) {
          margin-bottom: 1rem;
        }

        li {
          margin-right: 2rem;

          &:last-child {
            margin-right: 0;
          }

          @media (max-width: 1199px) and (max-height: 575px) {
            margin-right: 1rem;
          }

          button {
            outline: none;
            border: 1px solid transparent;
            background: linear-gradient(180deg, $black, $black) padding-box,
              linear-gradient(180deg,
                #6f6f6f 18.46%,
                #ffffff 77.53%,
                #3f3f3f 134.04%) border-box;
            transition: 200ms ease-in-out;
            font-size: 2.4rem;
            font-weight: 400;
            line-height: 3.4rem;
            color: rgba($white, 0.6);
            width: 6rem;
            height: 6rem;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            position: relative;

            @media (max-width: 1439px) and (max-height: 619px) {
              width: 5rem;
              height: 5rem;
              font-size: 2rem;
            }

            @media (max-width: 1439px) and (max-height: 575px) {
              width: 4rem;
              height: 4rem;
              font-size: 1.6rem;
            }

            @media (max-width: 1199px) and (max-height: 575px) {
              font-size: 1.2rem;
              width: 3rem;
              height: 3rem;
            }

            &::after {
              content: "";
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              background: linear-gradient(179.54deg,
                  #ffba55 0.39%,
                  rgba(255, 186, 85, 0.19) 50.37%,
                  #ffba55 100.26%);
              opacity: 0;
              transition: 200ms ease-in-out;
            }

            &:hover,
            &.active {
              &::after {
                opacity: 1;
              }
            }

            &:active {
              transform: scale(0.96);
            }
          }
        }
      }

      h4 {
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 2.2rem;
        color: $white;
        margin-bottom: 1rem;

        @media (max-width: 1199px) and (max-height: 619px) {
          font-size: 1.4rem;
          margin-bottom: 0.4rem;
          line-height: 2rem;
        }

        @media (max-height: 575px) and (max-width: 1439px) {
          font-size: 1.2rem;
        }

        @media (max-height: 479px) and (max-width: 1439px) {
          font-size: 1rem;
          line-height: 1.2;
          margin-bottom: 0.1rem;
        }
      }

      .qty {
        display: flex;
        align-items: center;

        &_inner {
          padding: 1.5rem 1.7rem;
          height: 6rem;
          border: 1px solid transparent;
          background: linear-gradient(0deg, #000000, #000000) padding-box,
            linear-gradient(180deg,
              #6f6f6f 18.46%,
              #ffffff 77.53%,
              #3f3f3f 134.04%) border-box;
          margin-right: 2rem;
          display: flex;
          align-items: center;

          @media (max-width: 1439px) or (max-height: 619px) {
            height: 5rem;
            padding: 1rem 0.8rem;
          }

          @media (max-height: 575px) and (max-width: 1439px) {
            height: 4.3rem;
          }

          @media (max-height: 479px) and (max-width: 1439px) {
            height: 3.1rem;
            padding: 0 0.5rem;
          }

          input {
            background-color: transparent;
            border: none;
            outline: none;
            width: 4rem;
            color: rgba($white, 0.6);
            font-size: 2.4rem;
            font-weight: 400;
            line-height: 3.4rem;
            letter-spacing: 0em;
            text-align: center;

            @media (max-width: 1439px) and (max-height: 619px) {
              font-size: 2rem;
              line-height: 3rem;
            }

            @media (max-height: 575px) and (max-width: 1439px) {
              font-size: 1.4rem;
              width: 3.4rem;
            }
          }

          button {
            background-color: transparent;
            border: none;
            outline: none;
            display: block;
            padding: 0;

            @media (max-width: 1439px) or (max-height: 619px) {
              svg {
                max-width: 1rem;
              }
            }
          }
        }

        .price {
          h5 {
            font-size: 2.5rem;
            font-weight: 400;
            line-height: 2.8rem;
            letter-spacing: 0em;
            text-align: left;
            color: $baseclr;

            @media (max-width: 1439px) and (max-height: 619px) {
              font-size: 2rem;
            }

            @media (max-width: 1199px) and (max-height: 575px) {
              font-size: 1.6rem;
              line-height: 2.4rem;
            }

            span {
              font-size: 1.2rem;

              @media (max-height: 599px) {
                font-size: 1rem;
              }
            }
          }
        }
      }

      .submit_btn {
        margin-top: 5.1rem;

        @media (max-height: 619px) {
          margin-top: 4rem;
        }

        @media (max-height: 575px) {
          margin-top: 3rem;
        }

        @media (max-height: 479px) {
          margin-top: 2rem;
          max-width: 10rem;
          height: 2.6rem;
          font-size: 1rem;
        }
      }
    }
  }
}