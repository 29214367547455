@import "../../../../assets/theme/_var.scss";

body {

    :global {

        .audio_modal_backdrop {
            &.show {
                opacity: 1;
                background-color: rgba($black, 2);
                backdrop-filter: blur(1rem);
            }
        }
    }
}

.audio_modal {
    :global {
        .modal-dialog {
            max-width: 50rem;

            .modal-content {
                text-align: center;

                .modal-body {
                    position: static;
                }
            }
        }
    }

    .continue_btn {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%) !important;
    }


    .close_btn {
        padding: 0;
        border: none;
        outline: none;
        background-color: transparent;
        position: absolute;
        top: 1.6rem;
        right: 1.6rem;
        z-index: 1;

        svg {
            width: 2rem;
            height: 2rem;
        }
    }
}
