@import "../../../assets/theme/_var.scss";


.share_page {
    min-height: 100dvh;
    z-index: 1;

    :global {
        .container {
            margin-inline: auto;
            padding-inline: 2rem;
        }
    }

    .share_page_video {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        object-fit: fill;
        z-index: -2;
    }

    // >div {
    //     >div {
    //         min-height: calc(100vh - 6rem) !important;

    //         @media (max-height: 619px) and (max-width: 1439px) {
    //             min-height: calc(100vh - 3rem) !important;
    //         }

    //         @media (max-height: 479px) and (max-width: 1199px) {
    //             min-height: calc(100vh - 2rem) !important;
    //         }
    //     }
    // }

    .logo {
        max-width: 10rem;
        margin-inline: auto;
        display: block;
        margin-bottom: 1.7rem;

        @media (max-height: 479px) {
            max-width: 4rem;
            margin-bottom: 1rem;
        }
    }

    .animation {
        max-width: 26.4rem;
        margin-inline: auto;

        @media (max-width: 1439px) {
            max-width: 25rem;
        }

        @media (max-width:1199px) {
            max-width: 20rem;
        }

        @media (max-height: 479px) {
            max-width: 15rem;
        }

        @media (max-height: 419px) {
            max-width: 12rem;
        }
    }


    @media (max-width:1199px) {
        .animation {
            max-width: 20rem;
        }
    }

    @media (max-height: 479px) and (max-width: 1439px) {
        .animation {
            max-width: 15rem;
        }
    }

    @media (max-height: 419px) and (max-width: 1439px) {
        .animation {
            max-width: 12rem;
        }
    }

    ul {
        display: flex;
        margin: 0 0 4rem;
        align-items: center;
        justify-content: center;

        @media (max-height: 479px) {
            margin: 0 0 2rem;
        }

        @media (max-height: 419px) {
            margin: 0 0 1rem;
        }

        li {
            margin-right: 2.4rem;

            @media (max-width: 479px) {
                margin-right: 1rem;
            }

            @media (max-height: 679px) and (max-width: 1199px) {
                margin-right: 1.8rem;
            }

            @media (max-height: 479px) and (max-width: 1199px) {
                margin-right: 1rem;
            }

            &:last-child {
                margin-right: 0;
            }

            a {
                width: 6rem;
                height: 6rem;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                background-color: $baseclr;
                border-radius: 50%;

                &:hover {
                    background-color: $yellowclr2;
                }

                @media (max-width: 1439px) {
                    width: 4.4rem;
                    height: 4.4rem;
                    padding: 1.2rem;

                    &.fb {
                        padding: 1.3rem;
                    }
                }

                @media (max-width: 1199px) {
                    width: 3.8rem;
                    height: 3.8rem;
                }

                @media (max-width: 991px) {
                    width: 3.4rem;
                    height: 3.4rem;
                }

                @media (max-width: 767px) {
                    width: 3rem;
                    height: 3rem;
                    padding: 0.8rem;

                    &.fb {
                        padding: 1rem;
                    }
                }

                @media (max-width: 479px) {
                    padding: 0.8rem;

                    &.fb {
                        padding: 1rem;
                    }
                }

                @media (max-height: 679px) and (max-width: 1199px) {
                    width: 4rem;
                    height: 4rem;

                }

                @media (max-height: 479px) and (max-width: 1199px) {
                    width: 3.4rem;
                    padding: 0.8rem;
                    height: 3.4rem;
                }
            }
        }
    }

    .back_btn {
        position: fixed;
        bottom: 4rem;
        right: 4rem;
        color: $black;
        background-color: transparent;
        border: none;
        outline: none;
        font-size: 1.8rem;
        color: $baseclr;
    }
}