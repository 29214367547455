@import "../../../assets/theme/_var.scss";

.participation {
    min-height: 100dvh;
    padding: 3rem 0;
    display: flex;
    align-items: center;

    @media (max-width: 1679px) {
        padding: 6rem 0;
    }

    @media (max-width: 1599px) {
        padding: 4rem 0;
    }

    @media (max-height: 419px) and (max-width: 991px) {
        padding: 2rem 0;
    }

    .heading {
        margin: 0 0 2rem;

        @media (max-width: 1439px) {
            margin: 1rem 0;
        }

        @media (max-height: 419px) {
            margin: 0.8rem 0;

            h2 {
                font-size: 1.2rem;
            }
        }

        @media (max-height: 419px) and (max-width: 991px) {
            margin: 0 0 0.8rem;
        }
    }

    .participate_game {
        padding-bottom: 0;

        >div {
            cursor: pointer;
            background: linear-gradient(180deg, $black, $black) padding-box,
                linear-gradient(180deg, #6F6F6F 28.75%, #FFFFFF 120.79%, #3F3F3F 208.82%) border-box;

            .participate_game_img {
                >img {
                    height: 18rem;
                    // object-position: 0 -1.7rem;
                    object-position: center;

                    @media (max-width: 1579px) and (max-height: 767px) {
                        height: 14rem;
                    }

                    @media (max-width: 1365px) {
                        height: 14rem;
                    }

                    @media (max-width: 991px) {
                        height: 14rem;
                    }

                    @media (max-width: 479px) {
                        height: 12rem;
                    }

                    @media (max-height: 575px) and (max-width: 991px) {
                        height: 8rem;
                    }

                }

                // &::after {
                //     content: unset !important;
                // }
            }

            .participate_game_title {
                justify-content: flex-end;
                padding-bottom: 0.8rem;

                h2 {
                    font-family: Aclonica;
                    font-size: 21px;
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: 0.01em;
                    max-width: 18.7rem;
                    margin-inline: auto;
                    text-align: center;

                    @media (max-width: 1439px) {
                        font-size: 1.8rem;
                    }

                    @media (max-width: 1365px) {
                        font-size: 1.6rem;
                    }

                    @media (max-width: 1199px) {
                        font-size: 1.4rem;
                        line-height: 2rem;
                    }

                    @media (max-width: 991px) {
                        font-size: 1.2rem;
                    }

                    @media (max-width: 767px) {
                        font-size: 1.1rem;
                        line-height: 1.4rem;
                    }
                }
            }
        }
    }

    .main_img {
        max-width: 74rem;
        margin: 0 auto;

        @media (max-width: 1579px) and (max-height: 767px) {
            max-width: 64rem;
        }

        @media (max-width: 991px) {
            max-width: 60rem;
        }

        @media (max-height: 575px) and (max-width: 991px) {
            max-width: 34rem;
        }

        @media (max-height: 479px) and (max-width: 991px) {
            max-width: 28rem;
        }

        .main_img_card {
            @media (max-height: 575px) and (max-width: 1199px) {
                padding-top: 2.3rem;
                padding-bottom: 0.8rem;
            }

            >div {
                &::after {
                    width: 146px !important;

                    @media (max-height: 575px) and (max-width: 1199px) {
                        width: 12rem !important;
                    }
                }

                .main_img_card_img {
                    >img {
                        height: 40rem;
                        object-position: bottom;

                        @media (max-width: 1599px) {
                            height: 30rem;
                        }

                        @media (max-width: 991px) {
                            height: 30rem;
                        }

                        @media (max-width: 479px) {
                            height: 24rem;
                        }

                        @media (max-height: 575px) and (max-width: 991px) {
                            height: 14rem;
                        }

                        @media (max-height: 479px) and (max-width: 991px) {
                            height: 10rem;
                        }
                    }
                }
            }
        }

        :global {
            .slick-list {
                .slick-track {
                    .slick-slide {
                        padding-inline: 2rem;
                    }
                }
            }
        }
    }

    :global {
        .container {
            max-width: 120.8rem;
            padding-inline: 4.5rem;

            @media (max-width: 1599px) {
                padding-inline: 7.5rem;
            }

            @media (max-width: 991px) and (max-height: 479px) {
                max-width: 78rem;
            }
        }

    }

    .slick_slider {
        width: 100%;

        :global {
            .slick-arrow {
                width: 6rem;
                height: 6rem;
                border-radius: 50%;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                top: unset;
                transform: translate(0, 50%);
                bottom: 10.2rem;

                &.slick-disabled {
                    opacity: 0.5;
                }

                &::before {
                    content: unset;
                }

                &.slick-prev {
                    background-image: url($s3bucket + "/left-slick-arrow.svg");
                    left: -6rem;
                }

                &.slick-next {
                    background-image: url($s3bucket + "/right-slick-arrow.svg");
                    right: -6rem;
                }

                @media (max-width: 1365px) {
                    bottom: 9rem;
                    width: 4rem;
                    height: 4rem;

                    &.slick-prev {
                        left: -4rem;
                    }

                    &.slick-next {
                        right: -4rem;
                    }
                }

                @media (max-width: 991px) {
                    bottom: 8rem;
                }

                @media (max-width: 479px) {
                    bottom: 7rem;
                }

                @media (max-height: 575px) and (max-width: 991px) {
                    bottom: 4rem;
                }

                @media (max-height: 575px) and (max-width: 1199px) {
                    width: 4rem;
                    height: 4rem;

                    &.slick-prev {
                        left: -4rem;
                    }

                    &.slick-next {
                        right: -4rem;
                    }
                }

                @media (max-height: 379px) and (max-width: 1199px) {
                    width: 3rem;
                    height: 3rem;
                }
            }

            .slick-list {
                padding-top: 2rem;
                overflow: clip visible;
                // @media (max-width: 1439px) {
                //     padding-top: 4rem;
                // }

                // @media (max-height: 419px) and (max-width: 1439px) {
                //     padding-top: 2rem;
                // }
                @media (max-height: 419px) and (max-width: 991px) {
                    padding-top: 1.4rem;
                }
            }

            .slick-track {
                display: flex;

                .slick-slide {
                    height: auto;
                    padding-inline: 2rem;
                    transition: 200ms ease-in-out;

                    @media (max-width: 1679px) {
                        padding-inline: 2rem;
                    }

                    @media (max-width: 1439px) {
                        padding-inline: 1.4rem;
                    }

                    @media (max-width: 991px) and (max-height: 479px) {
                        padding-inline: 0.7rem;
                    }

                    &.slick-current {
                        :local(.participate_game) {
                            >div {
                                background: linear-gradient(180deg, $black, $black) padding-box,
                                    linear-gradient(180deg, #FFBA55 33.25%, #FFBA55 67.37%, #FFBA55 100%) border-box;

                                .silver-bow {
                                    opacity: 0;
                                    display: none;
                                }

                                .golden-bow {
                                    display: block !important;
                                }
                            }
                        }
                    }

                    >div {
                        height: 100%;

                        >div {
                            height: 100%;

                            >div {
                                height: 100%;
                            }
                        }
                    }
                }
            }
        }
    }

    .no_types_avail {
        color: $baseclr;
        opacity: 0.7;
        text-align: center;

        @media (max-width: 1479px) {
            font-size: 1.4rem;
        }
    }
}


.under_txt {
    text-align: center;
    font-size: 1.4rem;
    color: $baseclr;
    margin-top: 1rem;


    @media (max-height: 575px) and (max-width: 991px) {
        font-size: 1rem;
        // margin-top: 0.6rem;
    }
}