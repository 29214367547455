@import "../../../../assets/theme/_var.scss";

.maintenance {
    :global {
        .modal-dialog {
            max-width: 80rem;
            padding: 1.5rem;
            margin: auto;
            min-height: 100dvh;

            @media (max-width: 575px) {
                max-width: 60rem;
            }

            @media (max-width: 479px) {
                max-width: 50rem;
            }

            @media (max-height: 619px) and (max-width: 1199px) {
                max-width: 50rem;
            }

            .modal-content {

                @media (min-width: 1440px) and (min-height: 700px) {
                    padding: 7rem 0 12rem;
                }

                .modal-body {
                    text-align: center;
                    position: static;


                    h2 {
                        color: $baseclr;
                        font-size: 2.7rem;
                        font-weight: 400;
                        line-height: 3.1rem;
                        letter-spacing: 0.01em;
                        text-align: center;

                        @media (max-width: 1439px) {
                            font-size: 2.2rem;
                            line-height: 3.4rem;
                        }

                        @media (max-width: 1199px) {
                            font-size: 2rem;
                            line-height: 3.2rem;
                        }

                        @media (max-width: 767px) {
                            font-size: 1.8rem;
                            line-height: 3rem;
                        }

                        @media (max-height: 619px) and (max-width: 1439px) {
                            font-size: 1.8rem;
                            line-height: 3rem;
                        }

                        @media (max-height: 479px) and (max-width: 1439px) {
                            font-size: 1.6rem;
                            line-height: 2.4rem;
                        }
                    }
                }
            }

        }
    }

    .img {
        margin: 6.3rem 0 5.2rem;

        @media (max-height: 619px) {
            max-width: 10rem;
            margin: 4rem 0;
        }

        @media (max-height: 399px) {
            max-width: 8rem;
            margin: 2rem 0;
        }
    }

    .refresh_btn {
        position: absolute;
        bottom: 0;
        left: 50%;
        translate: -50% 50%;
        width: 22.6rem;
        height: 5.7rem;
        font-size: 2rem;
        font-weight: 400;
        line-height: 2rem;
        letter-spacing: 0.01em;
        text-align: center;

        @media (max-height: 555px) and (max-width: 1199px) {
            width: 18rem;
            height: 4.6rem;
            font-size: 1.2rem;
        }

        // &:hover{
        //     background-image: url($s3bucket + "/btn-bg.png");
        // }
    }
}