@import "../../../assets/theme/_var.scss";

.home_footer {
  // position: fixed;
  // bottom: 0;
  width: 100%;
  // left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 3rem;
  border-top: 1px solid rgba($white, 0.4);
  z-index: 11;
  margin-top: auto;

  @media (max-width: 1599px) {
    padding: 1rem 2rem;
  }

  .footer_social {
    display: flex;
    border-left: 1px solid rgba($black, 0.4);
    margin-left: 1rem;

    @media (max-width: 575px) {
      border: none;
      justify-content: center;
      margin: 2rem 0 0;
    }

    li {
      margin-left: 1rem;

      a {
        width: 3.2rem;
        height: 3.2rem;
        border-radius: 0.4rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;

        svg {
          width: 2.4rem;
          height: 2.4rem;
          max-height: 100%;

          path {
            // fill: rgba($radioBg,
            //         1);
            fill: rgba($white, 0.5);
          }
        }

        &:hover {
          svg {
            path {
              fill: $white;
            }
          }
        }

        @media (max-width: 1439px) {
          width: 2.6rem;
          height: 2.6rem;
        }

        @media (max-width: 1199px) {
          width: 2.2rem;
          height: 2.2rem;
        }

        @media (max-width: 991px) {
          width: 1.8rem;
          height: 1.8rem;
        }
      }
    }
  }

  @media (max-width: 575px) {
    flex-wrap: wrap;
    text-align: center;

    .footer_right {
      justify-content: center;
      width: 100%;
      margin-top: 1rem;
    }

    h3 {
      width: 100%;
    }
  }

  h3 {
    font-size: 1.4rem;
    font-family: $secondaryfont;
    color: $white;
    max-width: 24rem;
    text-align: center;

    img {
      max-width: 6rem;
      margin-inline: auto;
      display: block;
    }

    @media (max-width: 1439px) {
      font-size: 1.4rem;

      img {
        max-width: 4rem;
      }
    }

    @media (max-width: 1199px) {
      img {
        max-width: 3rem;
      }
    }

    @media (max-height: 619px) and (max-width: 1439px) {
      font-size: 1.2rem;
    }
  }

  .footer_right {
    display: flex;
    align-items: center;
    margin-inline: -1.4rem;

    li {
      a {
        padding-inline: 1.4rem;
        // color: $black;
        color: $white;
        font-size: 1.6rem;
        text-align: center;
        font-family: $secondaryfont;

        @media (max-width: 1199px) {
          font-size: 1.4rem;
        }

        @media (max-width: 991px) {
          padding-inline: 0.4rem;
          font-size: 1rem;
        }

        @media (max-width: 819px) {
          font-size: 0.9rem;
        }
      }
    }
  }
}