@import "../../../assets/theme/_var.scss";

.games {
    // padding-top: 9.1rem;
    padding-top: 2rem;
    overflow: hidden;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;


    @media (max-width: 1199px) {
        height: auto;
        min-height: 100dvh;
    }


    .logo {
        max-width: 12rem;
        z-index: 1;

        @media (max-width: 1599px) {
            max-width: 10rem;
        }

        @media (max-width: 1199px) {
            max-width: 8rem;
        }

        @media (max-height: 379px) {
            max-width: 5.7rem;
        }
    }

    :global {
        .container {
            max-width: 175.2rem;
            padding-inline: 1.5rem;
            margin: auto;

            @media (max-width: 1919px) {
                max-width: 166rem;
            }

            @media (max-width: 1679px) {
                max-width: 136rem;
            }

        }
    }


    .games_inner {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        position: relative;
        z-index: 1;

        @media (max-height: 619px) {
            justify-content: flex-start;
            min-height: auto;
        }

        .games_logo {
            z-index: 1;
        }


        .animation {
            position: absolute;
            top: 50%;
            opacity: 0.8;
            left: 50%;
            z-index: -1;
            transform: translate(-50%, -50%);

            svg {
                max-width: unset;
                width: 90rem !important;

                @media (max-width: 1439px) {
                    width: 80rem !important;
                }

                @media (max-width: 991px) {
                    width: 60rem !important;
                }

                @media (max-height: 619px) and (max-width: 1199px) {
                    width: 70rem !important;
                }

                @media (max-height: 479px) and (max-width: 1199px) {
                    width: 60rem !important;
                }

                @media (max-height: 619px) and (max-width: 991px) {
                    width: 60rem !important;
                }

                @media (max-height: 575px) and (max-width: 991px) {
                    width: 50rem !important;
                }

            }
        }

        .mandir_card {
            &::after {
                background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 86.41%),
                    linear-gradient(0deg, rgba(17, 10, 10, 0.2), rgba(17, 10, 10, 0.4)),
                    linear-gradient(180deg, #6F6F6F 18.46%, #ffffff00 77.53%, #3F3F3F 134.04%),
                    linear-gradient(180deg, #ffba5500 33.25%, #ffba5500 67.37%, #ffba5500 100%);
            }
        }

        .game_card {
            >div {
                .game_card_img {
                    >img {
                        @media (max-height: 575px) and (max-width: 991px) {
                            height: 16rem;
                        }
                    }
                }
            }
        }

        :global {
            .slick-slider {
                width: 100%;
            }

            .slick-list {
                padding-top: 2rem;

                @media (max-height: 379px) {
                    padding-top: 1rem;
                }
            }

            .slick-track {
                display: flex;

                .slick-slide {
                    height: auto;
                    padding-inline: 3rem;
                    transition: 200ms ease-in-out;

                    @media (max-width: 1679px) {
                        padding-inline: 2rem;
                    }

                    @media (max-width: 1439px) {
                        padding-inline: 1.4rem;
                    }

                    @media (max-width: 767px) and (max-height: 479px) {
                        padding-inline: 0.7rem;
                    }

                    @media (min-width: 1440px) {
                        &[aria-hidden="true"] {
                            transform: translateY(-18rem);
                        }
                    }

                    >div {
                        height: 100%;

                        >div {
                            height: 100%;

                            >div {
                                height: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}