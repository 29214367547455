@import "../../../../assets/theme/_var.scss";

.gamplay_modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: $black;
    z-index: 104;

    video {
        height: 100vh;
        width: 100vw;
        object-fit: fill;
    }

    .crossIcon {
        position: absolute;
        top: 1.5rem;
        right: 1.5rem;
        border: none;
        z-index: 1;
        outline: none;
        background-color: $black;
        width: 3.2rem;
        height: 3.2rem;
        border-radius: 0.6rem;
        transition: 200ms ease-in-out;

        &:hover {
            background-color: $black;
        }

        svg {
            path {
                stroke: $baseclr;
            }
        }
    }

    .video_details {
        position: absolute;
        top: 4rem;
        left: 4rem;
        background-color: $white;
        padding: 1.8rem 2rem;
        border-radius: 1rem;
        z-index: 106;

        @media (max-width: 1439px) {
            padding: 1.4rem 1.8rem;
        }

        @media (max-width: 1199px) {
            font-size: 1.4rem;
            padding: 1rem 1.4rem;
        }

        @media (max-heigh: 479px) and (max-width: 1439px) {
            font-size: 1.2rem;
            padding: 0.8rem 1rem;
        }

        li {
            &:not(:last-child) {
                margin-bottom: 1rem;
            }
        }

        p {
            font-size: 1.8rem;

            @media (max-width: 1439px) {
                font: 1.6rem;
            }

            @media (max-width: 1199px) {
                font-size: 1.4rem;
            }

            @media (max-heigh: 479px) and (max-width: 1439px) {
                font-size: 1.2rem;
            }
        }
    }

    .donate_btn {
        position: absolute;
        bottom: 2rem;
        right: 2rem;
        background-color: transparent;
        border: none;
        outline: none;
        color: $black;
        font-size: 1.6rem;
        z-index: 1;
    }

    .back_btn {
        position: fixed;
        right: 3rem;
        bottom: 3rem;

        &.skip_btn {
            color: $black;
            background-color: transparent;
            border: none;
            outline: none;
            font-size: 1.8rem;
            color: $baseclr;
        }
    }
}