@import "../../../../assets/theme/_var.scss";

.site_desciption {
    :global {
        .modal-dialog {
            padding: 1.2rem;
            max-width: 52.9rem;
            margin: auto;
            min-height: 100dvh;

            .modal-content {
                border: 2px solid $baseclr2;
                background-color: $black23;
                box-shadow: 0px 0px 40px 0px rgba($baseclr2, 0.302);

                .modal-header {
                    border-bottom: 0;
                    padding: 1.7rem 1.7rem 3.2rem 1.7rem;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    h3 {
                        font-size: 1.8rem;
                        color: $baseclr2;

                        @media (max-width: 1439px) {
                            font-size: 1.6rem;
                        }

                        @media (max-width: 991px) {
                            font-size: 1.4rem;
                        }

                        @media (max-width: 767px) {
                            font-size: 1.2rem;
                        }
                    }

                    @media (max-height: 479px) and (max-width: 1199px) {
                        padding: 1rem 1.5rem;
                    }

                    @media (max-width: 575px) {
                        padding: 1rem;
                    }
                }

                .modal-body {
                    padding: 0 6rem 6.4rem;

                    @media (max-width: 1439px) {
                        padding: 0 4rem 4rem;
                    }

                    @media (max-width: 1199px) {
                        padding: 0 3rem 3rem;
                    }

                    @media (max-height: 619px) or (max-width: 991px) {
                        padding: 0 2rem 2rem;
                    }

                    @media (max-width: 575px) {
                        padding: 0 1rem 1rem;
                    }
                }
            }
        }
    }

    p {
        color: $textclr;
        font-size: 1.6rem;
        text-align: center;

        @media (max-width: 991px) {
            font-size: 1.4rem;
        }

        @media (max-width: 767px) {
            font-size: 1.2rem;
        }
    }

    .close_btn {
        padding: 0;
        border: none;
        outline: none;
        background-color: transparent;
    }
}