@import "../../../assets/theme/_var.scss";

@import "../../../assets/theme/_var.scss";

.question_right {
    video {
        margin-inline: auto;
        height: 100vh;
        object-fit: cover;
        object-position: top;
        display: block;
    }

    .skip_btn {
        position: fixed;
        right: 3rem;
        bottom: 3rem;
        color: $black;
        background-color: transparent;
        border: none;
        outline: none;
        font-size: 1.8rem;
        color: $baseclr;
    }

}