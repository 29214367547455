@import "../../../../assets/theme/_var.scss";

.gameplay_main {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    z-index: 1;

    >div {
        height: 100%;
        width: 100%;
        max-width: 100%;
        max-height: 100%;

        video {
            object-fit: fill;
        }
    }

    .skip_btn {
        position: fixed;
        right: 3rem;
        bottom: 3rem;

        color: $black;
        background-color: transparent;
        border: none;
        outline: none;
        font-size: 1.8rem;
        color: $baseclr;
    }
}