@import "../../../../assets/theme/_var.scss";

body {

    :global {

        .thanks_backdrop {
            &.show {
                opacity: 1;
                background-color: rgba($black, 0.0);
                backdrop-filter: blur(1rem);
            }
        }
    }
}

.thanks_modal {
    :global {
        .modal-dialog {
            padding: 1.4rem;
            max-width: 86rem;
            margin-inline: auto;

            .modal-content {
                border: 2px solid $baseclr2;
                // background-color: $black23;
                border-radius: 1rem;
                background-image: url($s3bucket + "/login-bg.webp");
                box-shadow: 0px 0px 40px 0px rgba($baseclr2, 0.302);
                background-size: cover;
                background-position: center;
                z-index: 0;
                padding: 5rem 2rem 6rem !important;

                @media (max-width: 1439px) and (max-height: 699px) {
                    padding: 3rem 2rem !important;
                }


                @media (max-width: 1199px) and (max-height: 579px) {
                    padding: 1.4rem 0 3rem !important;
                }

                &::after {
                    content: "";
                    z-index: -1;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    border-radius: inherit;
                    top: 0;
                    left: 0;
                    background-image: url($s3bucket + "/login-bg-overlay.webp");
                    background-size: cover;
                    background-position: top;
                    background-repeat: no-repeat;
                }
            }

            .modal-body {
                position: static;
                text-align: center;

                h3 {
                    color: $baseclr2;
                    font-size: 3.2rem;
                    // word-break: break-all;
                    word-wrap: break-word;

                    @media (max-width: 1679px) {
                        font-size: 2.8rem;
                    }

                    @media (max-width: 1439px) {
                        font-size: 2.4rem;
                    }

                    @media (max-width: 1199px) {
                        font-size: 1.8rem;
                    }

                    @media (max-height: 479px) {
                        font-size: 1.4rem;
                    }

                    @media (max-height: 389px) {
                        font-size: 1.2rem;
                    }
                }

                p {
                    font-size: 2.4rem;
                    color: $baseclr;
                    margin: 1.2rem 0;
                    // word-break: break-all;
                    word-wrap: break-word;

                    @media (max-width: 1679px) {
                        font-size: 2.2rem;
                    }

                    @media (max-width: 1439px) {
                        font-size: 2rem;
                    }

                    @media (max-width: 1199px) {
                        font-size: 1.8rem;
                        margin: 0;
                    }

                    @media (max-height: 479px) {
                        font-size: 1.4rem;
                    }

                    @media (max-height: 389px) {
                        font-size: 1.2rem;
                    }
                }

                ul {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin: 0 -0.8rem 2rem;

                    @media (max-height: 389px) {
                        margin-bottom: 1rem;
                    }

                    li {
                        padding-inline: 0.8rem;
                        flex: 1 0 calc(100% / 3);

                        h3 {
                            margin-top: 0.8rem;
                        }
                    }
                }
            }
        }
    }

    &.small_modal {
        :global {
            .modal-dialog {
                max-width: 60rem;

                @media (max-height: 589px) {
                    max-width: 46rem;
                }

                @media (max-height: 489px) {
                    max-width: 36rem;
                }

                .modal-content {
                    padding: 2rem;

                    .modal-body {
                        padding: 6rem 2rem;

                        @media (max-width: 1439px) {
                            padding: 4rem 2rem;
                        }

                        @media (max-width: 1199px) {
                            padding: 3rem 2rem;
                        }

                        h3 {
                            @media (max-height: 489px) {
                                font-size: 1.8rem !important;
                            }
                        }
                    }
                }
            }
        }
    }

    .allow_btn,
    .continue_btn {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%) !important;

        @media (max-height: 389px) {
            width: 14rem;
            font-size: 1.2rem;
            height: 3.4rem;
        }
    }

    // .continue_btn {
    //     margin-top: auto;
    // }

    .collectible_img {
        width: 20rem;
        height: 20rem;
        flex-shrink: 0;
        object-fit: contain;
        // max-height: calc(100vh - 13rem);

        @media (max-width: 1479px) {
            width: 16rem;
            height: 16rem;
        }

        @media (max-width: 1199px) and (max-height: 579px) {
            width: 12rem;
            height: 12rem;
        }

        @media (max-height: 389px) {
            width: 10rem;
            height: 10rem;
        }
    }
}