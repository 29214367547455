@import "../../../assets/theme/_var.scss";

.sound {
    width: 6rem;
    height: 6rem;
    position: absolute;
    top: 2rem;
    right: 2rem;
    border-radius: 50%;
    border: 2px solid rgba($baseclr, 0.3);
    // border: none;
    background-color: black;
    padding: 1.2rem;
    z-index: 100078;

    svg {
        path {
            fill: rgba($white, 0.6);
        }
    }

    &.active {
        border: 2px solid rgba($baseclr, 0.5);

        svg {
            path {
                fill: $white;
            }
        }
    }

    @media (max-width: 1199px) {
        width: 5rem;
        height: 5rem;
    }

    @media (max-height: 389px) {
        width: 4.6rem;
        height: 4.6rem;
    }
}