@import "../../../../assets/theme/_var.scss";

.input {
    @media (min-width: 576px) {
        align-items: flex-start;
        display: flex;
    }

    @media (max-width: 575px) {
        text-align: left;
    }

    label {
        white-space: nowrap;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 2.24rem;
        color: $baseclr;
        transform: translateY(2rem);

        @media (max-width: 1199px) {
            transform: translateY(0.8rem);
            font-size: 1.4rem;
        }

        @media (max-height: 619px) and (max-width: 1439px) {
            font-size: 1.2rem;
        }

        @media (max-width: 575px) {
            margin-bottom: 1rem;
        }


        span {
            &.required {
                position: relative;
                left: -1rem;
                font-size: 2rem;
            }
        }
    }

    select {
        width: 3.4rem;
        height: 4rem;
        position: absolute;
        left: 0;
        // top: 2.2rem;
        z-index: 1;
        opacity: 0;
        background-color: transparent;
        border: none;
        cursor: pointer;
        background-color: $black;
        color: $white;

        @media (max-width: 991px) {
            top: 1.3rem;
        }

        @media (max-height: 619px) and (max-width: 1439px) {
            top: 1.6rem;
        }

        @media (max-height: 479px) and (max-width: 1439px) {
            top: 1.3rem;
        }

        @media (max-height: 419px) and (max-width: 1439px) {
            top: 1rem;
        }
    }

    .PhoneInput {
        // display: flex;
        margin-left: 2rem;
        // align-items: center;
        position: relative;
        z-index: 2;

        @media (max-width: 575px) {
            margin-left: 0;
        }
    }

    :global {

        .PhoneInput {
            display: flex;
            // margin-left: 2rem;
            align-items: center;
            // position: relative;
            // z-index: 2;

            // @media (max-width: 575px) {
            //     margin-left: 0;
            // }

            .PhoneInputCountryIcon {
                position: relative;

                &::after {
                    content: "";
                    width: 2rem;
                    height: 2rem;
                    background-image: url(../../../../assets/svg/down-arrow.svg);
                    background-size: 1rem;
                    background-repeat: no-repeat;
                    background-position: center;
                    position: absolute;
                    top: 50%;
                    right: -2rem;
                    transform: translateY(-50%);
                }
            }

            .PhoneInputCountry {
                position: absolute;
                top: 2.2rem;
                left: 1rem;

                @media (max-width: 991px) {
                    top: 1.3rem;
                }

                @media (max-height: 619px) and (max-width: 1439px) {
                    top: 1.6rem;
                }

                @media (max-height: 479px) and (max-width: 1439px) {
                    top: 1.3rem;
                }

                @media (max-height: 419px) and (max-width: 1439px) {
                    top: 1rem;
                }

                img {
                    pointer-events: none;
                    width: 2.4rem;
                    z-index: 0;
                    // height: calc(100% - 2rem);
                }
            }
        }

        .PhoneInputInput {
            color: $white;
            font-family: $secondaryfont;
            width: 100%;
            font-size: 1.6rem;
            padding-inline: 5.4rem 2.1rem;
            font-weight: 400;
            background-color: rgba($black, 0.6);
            border-radius: 0.4rem;
            border: 1px solid transparent;
            outline: none;
            height: 6rem;

            @media (max-width: 991px) {
                height: 5rem;
            }

            @media (max-width: 575px) {
                border: 1px solid rgba($white, 0.12);
            }

            @media (max-height: 619px) and (max-width: 1439px) {
                height: 4.8rem;
                font-size: 1.4rem;
            }

            @media (max-height: 479px) and (max-width: 1439px) {
                height: 4rem;
            }

            @media (max-height: 419px) and (max-width: 1439px) {
                height: 3.6rem;
                font-size: 1.2rem;
            }

            &::placeholder {
                color: rgba($white, 0.5);
            }

            &:focus {
                border: 1px solid rgba($baseclr, 0.6);
            }
        }
    }
}