@import "../../../../assets/theme/_var.scss";

body {
    :global {
        .select_mode_backdrop {
            &.show {
                opacity: 1;
                background-color: rgba($black, 0.4);
                backdrop-filter: blur(1rem);
            }
        }
    }
}

.select_mode {
    .close_btn {
        padding: 0;
        border: none;
        outline: none;
        background-color: transparent;
        position: absolute;
        top: 1.6rem;
        right: 1.6rem;
        z-index: 1;

        svg {
            width: 2rem;
            height: 2rem;
        }
    }

    :global {
        .modal-dialog {
            max-width: 80.4rem;

            @media (max-width: 1479px) {
                max-width: 60rem;
            }

            @media (max-width: 1199px) and (max-height: 389px) {
                max-width: 48rem;
            }

            @media (max-width: 1199px) and (max-height: 359px) {
                max-width: 44rem;
            }

            .modal-content {
                background: linear-gradient(180deg, #000000 0%, rgba(18, 5, 1, 0.73) 47.84%) padding-box,
                    linear-gradient(180deg, #FFBA55 1.41%, rgba(255, 186, 85, 0.19) 51.8%, #FFBA55 100%) border-box;
                // linear-gradient(0deg, rgba(255, 186, 85, 0.08), rgba(255, 186, 85, 0.08));
                border: 2px solid transparent;
                border-radius: 0;
                padding: 4rem;

                @media (max-width: 1199px) and (max-height: 479px) {
                    padding: 2rem;
                }

            }
        }
    }

    .heading {
        margin-bottom: 3.4rem;

        @media (max-width: 1479px) {
            margin-bottom: 2rem;
        }

        @media (min-width: 1680px) {
            h2 {
                font-size: 3rem;
            }
        }
    }

    .list {
        display: flex;
        align-items: center;
        justify-content: center;

        .mode {
            width: 32.7rem;
            border: none;
            outline: none;
            height: 37.1rem;
            background-color: transparent;
            background-image: url($s3bucket + "/mode-selection.png");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            padding-top: 2.8rem;
            position: relative;
            z-index: 1;

            @media (max-width: 1479px) {
                width: 24rem;
                height: 27.2rem;
            }

            @media (max-width: 1199px) and (max-height: 479px) {
                width: 20rem;
                height: 22.6rem;
            }

            @media (max-width: 1199px) and (max-height: 389px) {
                width: 16rem;
                height: 18rem;
            }

            @media (max-width: 1199px) and (max-height: 359px) {
                width: 14.2rem;
                height: 16rem;
            }

            h3 {
                font-size: 3rem;
                font-weight: 400;
                line-height: 3.4rem;
                letter-spacing: 0.01em;
                text-align: center;
                color: $baseclr;

                @media (max-width: 1599px) {
                    font-size: 2.4rem;
                    line-height: 3rem;
                }

                @media (max-width: 1199px) {
                    font-size: 2.2rem;
                    margin-inline: 1rem;
                    line-height: 2.8rem;
                }

                @media (max-width: 991px) {
                    font-size: 2rem;
                    line-height: 2.6rem;
                }

                @media (max-width: 767px) {
                    font-size: 1.8rem;
                    line-height: 2.2rem;
                }

                @media (max-height: 619px) {
                    font-size: 2rem;
                }

                @media (max-height: 479px) {
                    font-size: 1.8rem;
                }

                @media (max-height : 479px) {
                    font-size: 1.6rem;
                }
            }

            img {
                position: absolute;
                bottom: 2px;
                left: 50%;
                transform: translateX(-50%);
                width: calc(100% - 0.4rem);
                height: calc(100% - 2.4rem);
                z-index: -1;
            }

            &:not(:last-child) {
                margin-right: 2rem;
            }

            &:hover,
            &.active {
                background-image: url($s3bucket + "/mode-selection-active.png");
            }
        }
    }
}